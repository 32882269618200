<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Transactions</h4>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createInvoice"
                      class="mt-1 btn btn-block btn-primary"
                      style="color:#fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add new invoice
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col class="d-flex" cols="12" sm="12" md="4">
                  <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.date_from"
                          label="View from date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-on:keyup.enter="searchCart()"
                          @input="search.date_from = $event !== null ? $event : ''"
                          :error="checkDateFrom"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.date_from"
                        no-title
                        @input="menu = false"
                    ></v-date-picker>
                  </v-menu>
<!--                  <span v-if="checkDateFrom" class="text-danger">This information is required !!</span>-->
                </v-col>

                <v-col class="d-flex" cols="12" sm="12" md="4">
                  <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.date_to"
                          label="View up to date"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          v-on:keyup.enter="searchCart()"
                          :error="checkDateTo"
                          @input="search.date_to = $event !== null ? $event : ''"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.date_to"
                        no-title
                        @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu><br>
<!--                  <span v-if="checkDateTo" class="text-danger">This information is required !!</span>-->
                </v-col>

                <v-col class="d-flex" cols="12" sm="12" md="4">
                  <v-text-field
                      label="Invoice number"
                      v-model="search.invoice"
                      outlined
                      dense
                      v-on:keyup.enter="searchCart()"
                      @input="search.invoice = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12" md="3">
                  <v-text-field
                      label="PO number"
                      v-model="search.po_number"
                      outlined
                      dense
                      v-on:keyup.enter="searchCart()"
                      @input="search.po_number = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12" md="2">
                  <v-select
                      label="Payment method"
                      v-model="search.payment_method"
                      :items="paymentMethodTypes"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      v-on:keyup.enter="searchCart()"
                      @input="search.payment_method = $event !== null ? $event : ''"
                      clearable
                  ></v-select>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12" md="3">
                  <v-autocomplete
                      :items="received_by_types"
                      v-model="search.received_by_type"
                      v-on:keyup.enter="searchReport"
                      label="Received by type"
                      item-text="text"
                      item-value="value"
                      @change="users=[];"
                      outlined
                      dense clearable
                  ></v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="12" md="4" v-if="search.received_by_type">
                  <v-autocomplete
                    outlined
                    dense
                    :items="users"
                    item-text="display_text"
                    item-value="id"
                    v-model="search.received_by"
                    label="User"
                    :loading="userLoading"
                    clearable
                    :search-input.sync="userSearch"
                    placeholder="Search by first name, last name or email"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <strong>Search by first name, last name or email </strong>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="12" class="text-right">
                  <v-btn
                      @click="searchCart()"
                      class="btn btn-primary text-white"
                      :loading="isLoading"
                      :disabled="checkDateFrom || checkDateTo"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>

            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Date</th>
                  <th class="px-3">Invoice number</th>
                  <th class="px-3">PO number</th>
                  <th class="px-3">Payment method</th>
                  <th class="px-3">Amount</th>
                  <th class="px-3">Status</th>
                  <th class="px-3">Received by</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="carts.length > 0" v-for="(item, index) in carts" :key="index">
                    <td class="width">
                        {{item.formatted_order_paid}}
                    </td>
                    <td class="px-3">
                      <span class="mb-1">
                        {{item.invoice_number }}
                      </span>
                    </td>
                    <td class="px-3">
                      <span class="mb-1">
                        {{item.po_number ? item.po_number : '-'}}
                      </span>
                    </td>
                    <td class="px-3">
                      <span class="mb-1">
                        {{item.payment_method ? item.payment_method_text : 'NA' }}
                      </span>
                    </td>
                    <td class="px-3">
                      <span class="mb-1">
                        {{item.currency_symbol ? item.currency_symbol : '$' }}
                        {{item.amount_paid ? item.amount_paid : item.amount_total }}
                      </span>
                      <div v-if="item.coupon_id" class="mt-2">
                                        <span class="badge badge-info">
                                          Coupon Discount: {{ item.score_currency ? item.score_currency : '$' }}{{ item.coupon_price }}
                                        </span><br><br>
                        <span class="font-weight-bold">
                                          Promo Code: {{ item.coupon_code || 'N/A' }}
                                        </span>
                      </div>
                    </td>
                    <td class="px-3">
                      <div v-if="item.payment_status">
                        <span v-html="item.payment_status_badge"></span>
                      </div>
                      <div class="mt-3">
                      <span class="mt-2 badge badge-success">
                        {{item.order_status_text}}
                      </span>
                      </div>
                    </td>
                    <td class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">
                      <div>
                        {{ item.received_by_full_name ?? 'NA' }}
                      </div>
                      <span class="font-weight-medium" v-if="item.received_by_type">({{item.received_by_type ? item.received_by_type_text : ''}})</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown size="sm" variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret right no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="item.can_view_invoice">
                              <a class="navi-link"  @click="viewInvoice(item.id)">
                                <span class="navi-icon">
                                    <v-icon color="darken-2">fas fa-file-invoice</v-icon>
                                </span>
                                <span class="navi-text">View invoice</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-else>
                              <a class="navi-link"  >
                                <span class="navi-icon">
                                    <v-icon color="darken-2">fas fa-file-invoice</v-icon>
                                </span>
                                <span class="navi-text"><strike>View invoice</strike></span>
                              </a>
                            </b-dropdown-text>

                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>

                      </template>
                    </td>
                  </tr>
                  <tr v-if="carts.length == 0">
                    <td colspan="10" class="text-center">
                      <strong>No data found</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading"
                            v-if="carts.length > 0"
                            class="pull-right mt-7"
                            @input="getAllCart"
                            v-model="page"
                            :total-rows="total"
                            :per-page="perPage"
                            first-number
                            last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-invoice ref="create-invoice"  @refresh="getAllCart" :userId="userId"></create-invoice>
    </div>
  </v-app>
</template>

<script>
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import AdminUserService from "@/services/admin-user/AdminUserService";
import CreateInvoice from "./CreateInvoice";

const accountHolder = new AccountholderService()
const adminUser = new AdminUserService();

export default {
  components:{
    CreateInvoice,
  },
  name: "TransactionSummary",
  data(){
    return{
      menu: false,
      menu1: false,
      isLoading: true,
      carts: [],
      paymentMethodTypes: [
        { name: "All", value: "" },
        { name: "Card", value: "card" },
        { name: "Paypal", value: "paypal" },
        { name: "SchoolPay", value: "schoolpay" },
        { name: "Alepay", value: "alepay" },
        { name: "Other", value: "other" }
      ],
      total: null,
      perPage: null,
      page: null,
      search:{
        transaction_id: '',
        invoice: '',
        po_number: '',
        date_from: '',
        date_to: '',
        payer_name: '',
        payer_email: '',
        payment_method: '',
        received_by: null,
        received_by_type: ''
      },
      userTypes: [
        { name: "Examiner", value: 'is_examiner'},
        { name: "Supervisor", value: 'is_supervisor'},
      ],
      received_by_types:[
        {
          text: 'Local',
          value: 'local'
        },
        {
          text: 'Federal credit card',
          value: 'federal_credit_card'
        },
        {
          text: 'Federal paypal',
          value: 'federal_paypal'
        }
      ],
      userSearch: null,
      userLoading: false,
      users: []
    }
  },
  computed: {
    checkDateFrom() {
      return (new Date(this.search.date_to) <= new Date(this.search.date_from)) || (this.search.date_to != '' && this.search.date_from == '');
    },
    checkDateTo() {
      return (new Date(this.search.date_to) <= new Date(this.search.date_from)) || (this.search.date_from != '' && this.search.date_to == '');
    },
    userId() {
      if(this.$route.params.accountholderId)
        return this.$route.params.accountholderId;
      else if(this.$route.params.examinerId)
        return this.$route.params.examinerId;
      else if(this.$route.params.onlineMarkerId)
        return this.$route.params.onlineMarkerId;
      else
        return this.$route.params.onlineMarkerId;
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    },
  },
  mounted() {
    this.getAllCart();
  },
  methods: {
    searchCart() {
      this.getAllCart();
    },
    viewInvoice(cartId) {
      accountHolder
          .viewInvoice(this.userId, cartId);
    },
    getAllCart() {
      accountHolder.getAllCart(this.userId, this.search, this.page).then(response => {
        this.carts = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      })
          .catch(() => {})
          .finally(() =>
              this.isLoading = false
          )
    },
    createInvoice(){
      this.$refs['create-invoice'].createInvoice();
    },
  },
  watch:{
    userSearch(oldVal, newVal) {      
      if(oldVal == newVal){
        return false;
      }

      this.users = [];
      let data = {
        name:newVal,
        access_type: this.search.received_by_type == 'local' ? 'score' : 'federal',
        score_id: null
      };

      adminUser
      .search(data)
      .then((response) => {
          response.data.adminUsers.map((user) => {
              let data = user;
              data.display_text = user.full_name+'('+user.email+')' ;
              this.users.push(data);
          });
      })
      .catch((err) => {

      })
      .finally(() => (this.userLoading = false));
    },
  }
}
</script>

<style scoped>
.width {
  width: 110px !important;
}
</style>